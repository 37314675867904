<template>
  <OnboardingStep
    :title="title"
    :next-text="$t('onboarding.welcomeView.nextText')"
    v-bind="$props"
  >
    <I18nArray tag="p" path="onboarding.welcomeView.paragraphs" />
    <div class="welcome-video">
      <video :width="videoWidth" height="400" controls>
        <source src="videos/learn-more.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import I18nArray from "@/components/I18nArray";
import { hasActiveEmployeeAccess } from "@/mixins/employee";

export default {
  name: "WelcomeView",
  components: { I18nArray },
  extends: BaseStepView,
  mixins: [hasActiveEmployeeAccess],
  computed: {
    title() {
      return this.$t("onboarding.welcomeView.title", {
        fullName: this.computedActiveEmployee?.fullName,
      });
    },
    videoWidth() {
      return this.$vuetify.breakpoint.mobile ? "100%" : "500";
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome-video {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
</style>
